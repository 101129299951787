import { createSlice } from "@reduxjs/toolkit";
import { getLocation } from "../actions/home";
import axiosInstance from "../../Axios";

const initialState = {
  pageLoading: false,
  errorMessage: "",
  location: null,
};

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLocation.pending, (state) => {
      state.pageLoading = true;
    });
    builder.addCase(getLocation.fulfilled, (state, action) => {
      state.location = action.payload.country_name;
      axiosInstance.defaults.headers.common["Location"] =
        action.payload.country_name;

      // state.location = "UNITED KINGDOM";
      // axiosInstance.defaults.headers.common["Location"] = "UNITED KINGDOM";

      // state.location = "AFGHANISTAN";
      // axiosInstance.defaults.headers.common["Location"] = "AFGHANISTAN";

      state.pageLoading = false;
    });
    builder.addCase(getLocation.rejected, (state, action) => {
      state.pageLoading = false;
      let msg = "Something went wrong, Please try again later.";
      if (action.payload) {
        if (typeof action.payload === "string") {
          msg = action.payload;
        } else if (typeof action.payload.message === "string") {
          msg = action.payload.message;
        }
      }
      state.errorMessage = msg;
    });
  },
});

export default homeSlice.reducer;
