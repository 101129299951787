import React, { useRef } from "react";
import { VscChromeClose } from "react-icons/vsc";
import PropTypes from "prop-types";
import { toThousandFormat } from "../../Constant";

const GiftsModal = ({
  openModal,
  setOpenModal,
  showModal,
  gift,
  category,
  addGift,
}) => {
  const modalRef = useRef();

  window.addEventListener("click", (e) => {
    if (e.target === modalRef.current) {
      setOpenModal(!openModal);
    }
  });

  return (
    <section>
      <div
        ref={modalRef}
        className="bg-[#00000087] z-50 flex flex-row flex-wrap h-full justify-center items-center fixed inset-0 w-full overflow-auto transition-all duration-700 ease-out"
      >
        <div className="max-w-[800px] w-full py-24 p-3 m-0">
          <div className="cursor-pointer flex justify-end items-center  mb-3">
            <button className="bg-white w-8 h-8 rounded-full flex justify-center items-center">
              <VscChromeClose
                color="#f9508c"
                size={20}
                onClick={showModal}
                openModal={openModal}
              />
            </button>
          </div>
          <div className="bg-white rounded-xl p-4 sm:p-9 shadow-[0_2px_8px_#0003]">
            <div className="flex flex-row flex-wrap justify-between items-start mb-5">
              <div className="max-w-[500px] w-[calc(100%-2px)]">
                <h3 className="text-lg sm:text-2xl font-bold text-jr_pink">
                  {gift.name}
                </h3>
                <p className="text-sm sm:text-base text-jr_lightBlack">
                  {category}
                </p>
              </div>
            </div>
            <div>
              <div>
                <div className="grid gap-10 md:gap-5 md:grid-cols-[1fr_0.6fr]">
                  <div>
                    <div className="text-xs sm:text-sm text-jr_lightBlack my-6">
                      {gift.description}
                    </div>
                  </div>

                  <div>
                    <div className="p-1 bg-jr_grey rounded-md">
                      <h3 className="text-sm sm:text-base text-black font-semibold text-center mt-2">
                        {gift.currency_symbol}
                        {toThousandFormat(gift.amount)}
                      </h3>
                      <div className="overflow-hidden rounded-md  my-5">
                        <img
                          src={gift.image}
                          alt="joyribbons"
                          className="w-full"
                        />
                      </div>
                      <div>
                        <button
                          className="bg-jr_pink w-full flex items-center content-center cursor-pointer text-sm uppercase mt-3 flex-nowrap font-black h-12 justify-center text-white"
                          onClick={() => addGift(gift)}
                        >
                          Add to registry
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

GiftsModal.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  showModal: PropTypes.func,
  gift: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.string,
    description: PropTypes.string,
    amount: PropTypes.string,
    currency_symbol: PropTypes.string,
  }),
  category: PropTypes.string,
  addGift: PropTypes.func,
};

export default GiftsModal;
