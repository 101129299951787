import React from "react";
import { Link } from "react-router-dom";

const Overview = () => {
  return (
    <section className="pt-12">
      <div className="container">
        <div className="flex flex-col justify-start  min-h-[600px] sm:min-h-[65vh]  pb-24 overflow-x-hidden">
          <div>
            <div>
              <h3 className="text-2xl md:text-4xl  text-jr_black font-black">Overview</h3>
            </div>

            <div className="py-12">
              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-10 sm:gap-5">
                <Link
                  to="/analytics"
                  className="bg-[50%] bg-no-repeat bg-cover rounded-md overflow-hidden  bg-white border-2 shadow-[1px_1px_3px_0px_#b9b9b975] cursor-pointer"
                >
                  <div className="min-h-[200px] p-5 relative flex flex-col gap-5 justify-center items-center">
                    <img
                      src="https://res.cloudinary.com/dndntw861/image/upload/v1730739236/JoyRibbons/analytics_h38smo.png"
                      alt="joyribbons"
                      className="h-8 w-8"
                    />
                    <h3 className="text-base md:text-lg  text-jr_lightBlack  font-black">Analytics</h3>
                    <p className="text-sm md:text-base text-center">
                      See how many people confirmed they are coming for your wedding, how many gifts has been purchased
                      for you
                    </p>
                  </div>
                </Link>

                <Link
                  to="/wedding-registry"
                  className="bg-[50%] bg-no-repeat bg-cover rounded-md overflow-hidden  bg-white border-2 shadow-[1px_1px_3px_0px_#b9b9b975] cursor-pointer"
                >
                  <div className="min-h-[200px] p-5 relative flex flex-col gap-5 justify-center items-center">
                    <img
                      src="https://res.cloudinary.com/dndntw861/image/upload/v1730739242/JoyRibbons/wedding-registry_tqbr2g.png"
                      alt="joyribbons"
                      className="h-8 w-8"
                    />
                    <h3 className="text-base md:text-lg  text-jr_lightBlack  font-black"> Website Details</h3>
                    <p className="text-sm md:text-base text-center">
                      Every information about your website is here; add gifts you&apos;d love here
                    </p>
                  </div>
                </Link>

                <Link
                  to="/registry-link"
                  className="bg-[50%] bg-no-repeat bg-cover rounded-md overflow-hidden  bg-white border-2 shadow-[1px_1px_3px_0px_#b9b9b975] cursor-pointer"
                >
                  <div className="min-h-[200px] p-5 relative flex flex-col gap-5 justify-center items-center">
                    <img
                      src="https://res.cloudinary.com/dndntw861/image/upload/v1730739248/JoyRibbons/wedding-link_rsgbuy.png"
                      alt="joyribbons"
                      className="h-8 w-8"
                    />
                    <h3 className="text-base md:text-lg  text-jr_lightBlack  font-black">My Wedding Link</h3>
                    <p className="text-sm md:text-base text-center">See your website link and start sharing </p>
                  </div>
                </Link>

                {/* <Link
                  to="/wedding-venue"
                  className="bg-[50%] bg-no-repeat bg-cover rounded-md overflow-hidden  bg-white border-2 shadow-[1px_1px_3px_0px_#b9b9b975] cursor-pointer"
                >
                  <div className="min-h-[200px] p-5 relative flex flex-col gap-5 justify-center items-center">
                    <img src="/images/wedding-venue.png" alt="joyribbons" className="h-8 w-8" />
                    <h3 className="text-base md:text-lg  text-jr_lightBlack  font-black">Search Wedding Venue</h3>
                    <p className="text-sm md:text-base text-center">
                      Looking for wedding venues? Search some ideas here
                    </p>
                  </div>
                </Link> */}

                <Link
                  to="/profile"
                  className="bg-[50%] bg-no-repeat bg-cover rounded-md overflow-hidden  bg-white border-2 shadow-[1px_1px_3px_0px_#b9b9b975] cursor-pointer"
                >
                  <div className="min-h-[200px] p-5 relative flex flex-col gap-5 justify-center items-center">
                    <img
                      src="https://res.cloudinary.com/dndntw861/image/upload/v1730739249/JoyRibbons/profile_ouze06.png"
                      alt="joyribbons"
                      className="h-8 w-8"
                    />
                    <h3 className="text-base md:text-lg  text-jr_lightBlack  font-black">View My Profile</h3>
                    <p className="text-sm md:text-base text-center">
                      Add your bank account details, update your password{" "}
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Overview;
