import { createSlice } from "@reduxjs/toolkit";
import { getRates } from "../actions/rate";

const initialState = {
  pageLoading: false,
  errorMessage: "",
  successMessage: "",
  currencies: [],
};

export const rateSlice = createSlice({
  name: "rate",
  initialState,
  reducers: {
    resetRateState: (state) => {
      state.errorMessage = "";
      state.successMessage = "";
      state.pageLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRates.pending, (state) => {
      state.errorMessage = "";
      state.successMessage = "";
      state.pageLoading = true;
    });
    builder.addCase(getRates.fulfilled, (state, action) => {
      state.pageLoading = false;
      state.errorMessage = "";
      state.currencies = action.payload.data;
    });
    builder.addCase(getRates.rejected, (state, action) => {
      state.pageLoading = false;
      let msg = "Failed to fetch currency list. Please try again later.";
      if (action.payload) {
        if (typeof action.payload === "string") {
          msg = action.payload;
        } else if (typeof action.payload.message === "string") {
          msg = action.payload.message;
        }
      }
      state.errorMessage = msg;
    });
  },
});

export const { resetRateState } = rateSlice.actions;
export default rateSlice.reducer;
