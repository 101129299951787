import React, { useState, useEffect } from "react";
import BackButton from "../components/BackButton";
import RsvpTable from "../components/Tables/RsvpTable";
import { useSelector, useDispatch } from "react-redux";
import { getRsvp } from "../store/actions/rsvp";
import { resetRsvpState } from "../store/slices/rsvp";
import PageLoader from "../components/PageLoader";
import { perPage } from "../Constant";
import NoRecordFound from "../components/NoRecordFound";
import ErrorFound from "../components/ErrorFound";
import PageNavigation from "../components/PageNavigation";

const Rsvp = () => {
  const dispatch = useDispatch();
  const [pageIndex, setPageIndex] = useState(1);
  const pageLoading = useSelector((state) => state.rsvp.pageLoading);
  const dataList = useSelector((state) => state.rsvp.dataList);
  const currentPage = useSelector((state) => state.rsvp.currentPage);
  const previousPage = useSelector((state) => state.rsvp.previousPage);
  const nextPage = useSelector((state) => state.rsvp.nextPage);
  const emptyState = useSelector((state) => state.rsvp.emptyState);
  const errorState = useSelector((state) => state.rsvp.errorState);
  const errorMessage = useSelector((state) => state.rsvp.errorMessage);

  useEffect(() => {
    const payload = {
      pageSize: perPage,
      pageIndex,
    };

    dispatch(getRsvp(payload));

    return () => {
      dispatch(resetRsvpState());
    };
  }, [pageIndex]);

  const getPageNumber = (val) => {
    setPageIndex(val);
    window.scroll({ top: 0, behavior: `instant` });
  };

  return (
    <section className="pt-12">
      <div className="container">
        <div className="flex flex-col justify-start  min-h-[600px] sm:min-h-[65vh] pb-24 overflow-x-hidden">
          <div className="flex justify-between mb-10 flex-row gap-6 flex-wrap items-center">
            <BackButton />
          </div>

          <div className="text-left">
            <h3 className="text-2xl md:text-4xl  text-black  font-black">
              RSVP
            </h3>
          </div>

          <div className="py-12">
            {pageLoading && <PageLoader />}
            {!pageLoading && emptyState && (
              <div>
                <NoRecordFound title="Oops! No record found." />
              </div>
            )}
            {!pageLoading && errorState && (
              <div>
                <ErrorFound title={errorMessage} />
              </div>
            )}
            {dataList.length > 0 && (
              <>
                <RsvpTable data={dataList} />
                <PageNavigation
                  currentPage={currentPage}
                  previousPage={previousPage}
                  nextPage={nextPage}
                  applyPageNumber={getPageNumber}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Rsvp;
