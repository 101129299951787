import { configureStore } from "@reduxjs/toolkit";
import homeReducer from "./slices/home";
import authReducer from "./slices/auth";
import passwordReducer from "./slices/password";
import registerReducer from "./slices/register";
import tokenReducer from "./slices/token";
import emailReducer from "./slices/email";
import overviewReducer from "./slices/overview";
import messageReducer from "./slices/message";
import rsvpReducer from "./slices/rsvp";
import giftReducer from "./slices/gift";
import revenueReducer from "./slices/revenue";
import accountReducer from "./slices/account";
import bankReducer from "./slices/bank";
import weddingReducer from "./slices/wedding";
import storyReducer from "./slices/story";
import pictureReducer from "./slices/picture";
import categoryReducer from "./slices/category";
import registryReducer from "./slices/registry";
import rateReducer from "./slices/rate";

export const store = configureStore({
  reducer: {
    home: homeReducer,
    auth: authReducer,
    password: passwordReducer,
    register: registerReducer,
    token: tokenReducer,
    email: emailReducer,
    overview: overviewReducer,
    message: messageReducer,
    gift: giftReducer,
    revenue: revenueReducer,
    account: accountReducer,
    bank: bankReducer,
    wedding: weddingReducer,
    story: storyReducer,
    picture: pictureReducer,
    category: categoryReducer,
    registry: registryReducer,
    rsvp: rsvpReducer,
    rate: rateReducer,
  },
});
