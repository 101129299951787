import React, { useState, useEffect } from "react";
import BackButton from "../components/BackButton";
import AmountFromPurchasedGiftsTable from "../components/Tables/AmountFromPurchasedGiftsTable";
import WithdrawModal from "../components/Modal/WithdrawModal";
import ConfirmModal from "../components/Modal/ConfirmModal";
import {
  getAmountPurchased,
  withdrawPendingAmount,
} from "../store/actions/revenue";
import { resetRevenueState } from "../store/slices/revenue";
import PageLoader from "../components/PageLoader";
import { perPage } from "../Constant";
import NoRecordFound from "../components/NoRecordFound";
import ErrorFound from "../components/ErrorFound";
import PageNavigation from "../components/PageNavigation";
import { useSelector, useDispatch } from "react-redux";
import { toThousandFormat } from "../Constant";

const AmountFromPurchasedGifts = () => {
  const dispatch = useDispatch();
  const [pageIndex, setPageIndex] = useState(1);
  const pageLoading = useSelector((state) => state.revenue.pageLoading);
  const bottonLoading = useSelector((state) => state.revenue.bottonLoading);
  const dataList = useSelector((state) => state.revenue.amountPurchasedList);
  const currentPage = useSelector((state) => state.revenue.currentPage);
  const previousPage = useSelector((state) => state.revenue.previousPage);
  const nextPage = useSelector((state) => state.revenue.nextPage);
  const emptyState = useSelector((state) => state.revenue.emptyState);
  const errorState = useSelector((state) => state.revenue.errorState);
  const errorMessage = useSelector((state) => state.revenue.errorMessage);
  const successMessage = useSelector((state) => state.revenue.successMessage);

  const [confirmWithDraw, setConfirmWithDraw] = useState(false);
  const [withDrawModal, setWithDrawModal] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    getNewRecord();

    return () => {
      dispatch(resetRevenueState());
    };
  }, [pageIndex]);

  useEffect(() => {
    if (dataList.length > 0) {
      let amount = 0;
      dataList.forEach((item) => {
        if (item.status === "ACTIVE") {
          amount = amount + Number(item.amount);
        }
      });
      setTotalAmount(amount);
    }
  }, [dataList]);

  useEffect(() => {
    if (errorMessage) {
      setWithDrawModal(false);
    }

    if (successMessage) {
      setWithDrawModal(true);
      getNewRecord();
    }
  }, [errorMessage, successMessage]);

  const getNewRecord = () => {
    const payload = {
      pageSize: perPage,
      pageIndex,
    };

    dispatch(getAmountPurchased(payload));
  };

  const getPageNumber = (val) => {
    setPageIndex(val);
    window.scroll({ top: 0, behavior: `instant` });
  };

  const confirmWithDrawal = () => {
    setConfirmWithDraw(true);
  };

  const handleWithdraw = () => {
    const payload = {
      amount: totalAmount,
    };

    dispatch(withdrawPendingAmount(payload));
    setConfirmWithDraw(false);
  };

  useEffect(() => {
    if (withDrawModal) {
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = "18px";
    } else {
      document.body.style.overflow = "unset";
      document.body.style.paddingRight = 0;
    }
  }, [withDrawModal]);

  return (
    <>
      <section className="pt-12">
        <div className="container">
          <div className="flex flex-col justify-start  min-h-[600px] sm:min-h-[65vh] pb-24 overflow-x-hidden">
            <div className="flex justify-between mb-10 flex-row gap-6 flex-wrap items-center">
              <BackButton />
            </div>

            <div>
              <div className="flex justify-between mb-10 flex-col sm:flex-row gap-6  lg:items-center">
                <div className="lg:w-1/2">
                  <h3 className="text-2xl md:text-4xl  text-black  font-black">
                    Money Breakdown
                  </h3>
                </div>
                {totalAmount > 0 && (
                  <div className="grid gap-5 items-center w-3/5 sm:w-1/3 lg:w-1/4">
                    <button
                      onClick={confirmWithDrawal}
                      className="bg-jr_pink  w-full flex items-center content-center cursor-pointer text-sm sm:text-base flex-nowrap font-black h-12 justify-center"
                    >
                      <span className="text-sm text-white sm:text-base font-black flex items-center justify-center truncate">
                        Withdraw
                      </span>
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="py-12">
              {pageLoading && <PageLoader />}
              {!pageLoading && emptyState && (
                <div>
                  <NoRecordFound title="Oops! No record found." />
                </div>
              )}
              {!pageLoading && errorState && (
                <div>
                  <ErrorFound title={errorMessage} />
                </div>
              )}

              {dataList.length > 0 && (
                <>
                  <AmountFromPurchasedGiftsTable data={dataList} />
                  <PageNavigation
                    currentPage={currentPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    applyPageNumber={getPageNumber}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </section>

      {confirmWithDraw && (
        <ConfirmModal
          title="Confirm Withdrawal"
          message={`Are you sure you want to withdraw the amount ₦${toThousandFormat(
            totalAmount
          )} which is the total  pending to be withdrawn?`}
          closeModal={() => setConfirmWithDraw(false)}
          bottonLoading={bottonLoading}
          handleSubmit={handleWithdraw}
        />
      )}

      {withDrawModal && (
        <WithdrawModal closeModal={() => setWithDrawModal(false)} />
      )}
    </>
  );
};

export default AmountFromPurchasedGifts;
